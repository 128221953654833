import styles from './Spizarnia24Footer.module.scss';
import { getWebshopFooter } from '../../../containers/cms-container/services';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
const przelewy24Icon = require("../../../assets/payments/przelewy24.svg").default;


const getFooter = (languageCode: string): any => getWebshopFooter(languageCode);
const decodeHtml = (html: any) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
};
export default function Spizarnia24Footer() {

    const [footer, setFooter] = useState<string>();
    const getLanguageCode = (): string | null => localStorage.getItem("lang");


    useEffect(() => {
        const languageCode = getLanguageCode();

        if (languageCode) {
            getFooter(languageCode)
                .then((response: any) => {
                    const decodedHtml = decodeHtml(response.data);
                    setFooter(decodedHtml);
                })
                .catch((error: any) => {
                    console.error("Błąd pobierania footer:", error);
                });
        }
    }, []);

    return (
        <div className={styles.footer_container}>
            {footer && parse(DOMPurify.sanitize(footer))}
            <div className={styles.second_row}>
                <div className={styles.copyright}>
                    <div className={styles.left}>
                        <p>Powered by IT PLM 2024 © IsoTech Sp. z o.o.. All Rights Reserved</p>
                    </div>
                    <div className={styles.right}>
                        <img src={przelewy24Icon} alt="Przelewy24" />
                    </div>
                </div>
            </div>
        </div >
    )
}